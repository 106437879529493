import {
  SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  SPARKY_NAV3_ROUTES_EXTPOINT,
  SparkyLeftNavConfig,
  SparkyNavRouteConfig,
} from '@sparky/framework/extpoints';

import { ExtensionsConfig, getPropertyValue } from '@sparky/framework';
import { nav, routeUrls } from './routeUrls';
import { MFE_PROPERTY_NAME } from '@utils/constants';

export const scmNavLinks: object = {
  navigation: [
    {
      key: 'manage',
      title: 'Manage',
      expandable: true,
      // path: '/manage',
      noRBACheck: true,
      exact: false,
      mergeNav: true,
      children: [
        {
          key: 'configuration',
          title: 'Configuration',
          // path: '/manage/configuration',
          noRBACheck: true,
          exact: false,
          mergeNav: true,
          contentClassName: 'panwds-tw3',
          children: [
            {
              key: 'adns-resolver',
              title: 'ADNS Resolver',
              path: routeUrls.adnsResolver,
              noRBACheck: true,
              contentClassName: 'panwds-tw3',
              component: () => import('./Routes'),
            },
          ],
        },
      ],
    },
  ],
};

export const nav3LeftNavExt: ExtensionsConfig<SparkyLeftNavConfig> = {
  point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  configs: [
    {
      id: 'sparky.nav.left.adns-resolver',
      config: {
        navigation: () => [
          {
            key: 'configuration',
            title: 'Configuration',
            path: '/configuration',
            contentClassName: 'panwds-tw3',
            children: [
              {
                key: 'adns-resolver',
                title: 'ADNS Resolver',
                path: '/configuration/adns-resolver',
                replacePaths: routeUrls.adnsResolver,
              },
            ],
            avail: () => checkMfePSIfEnabled(),
          },
        ],
      },
    },
  ],
};

export const nav3routeExt: ExtensionsConfig<SparkyNavRouteConfig> = {
  point: SPARKY_NAV3_ROUTES_EXTPOINT,
  configs: [
    {
      id: 'sparky.nav.routes.adns-resolver',
      config: {
        path: '/configuration/adns-resolver',
        replacePaths: routeUrls.adnsResolver,
        component: () => import('./Routes'),
        contentClassName: 'panwds-tw3',
        mapStateToProps: (state) => {
          nav.isV3Nav = true;
          return {
            tsgId: state.auth.tsg_id,
          };
        },
        isAllowed: () => checkMfePSIfEnabled(),
      },
    },
  ],
};


// check MFE property store to see the tenant has access to the adns
export function checkMfePSIfEnabled(): boolean {
  const mfeProperties = getPropertyValue(MFE_PROPERTY_NAME);

  return mfeProperties?.enabled === 'true';
}
