import {
  checkMfePSIfEnabled,
  nav3LeftNavExt,
  nav3routeExt,
  scmNavLinks,
} from '@routes/scm-nav-links';
import { Extensions } from '@sparky/framework';

export default async function init() {
  return function tsgLoad() {
    if (checkMfePSIfEnabled()) {
      return scmNavLinks;
    }
    return null;
  };
}

export const extensions: Extensions = [nav3LeftNavExt, nav3routeExt];
