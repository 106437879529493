import React from 'react';

export const EMPTY_STATE_PROPS = {
  graphic: <br />,
  heading: '',
};

export const GRACE_PERIOD_DAYS = 30;

export const MFE_PROPERTY_NAME = 'zdns.settings';
